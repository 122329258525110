import React from 'react'

import Tag from './tag'

const Tags = ({ tags }) => {
  return (
    <div className="tags my-2">
      {tags.map(tag => {
        return <Tag key={`tag-${tag.tagName}`} name={tag.tagName} />
      })}
    </div>
  )
}

export default Tags
