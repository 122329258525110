import React from 'react'

const Tag = ({ name }) => {
  return (
    <div className="tag bg-theme1-bg-2 font-sans font-thin inline m-2 first:mx-0 px-3 py-1 rounded-lg text-theme1-primary text-lg">
      {name}
    </div>
  )
}

export default Tag
