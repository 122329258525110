import React from 'react'

export default ({ sidewaysLabel, children }) => {
  return (
    <div>
      {sidewaysLabel}
      {children}
    </div>
  )
}
