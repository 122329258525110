import React from 'react'
import { graphql } from 'gatsby'

import { shortAuthor } from '../utils'
import { oliviaAltNavLinks, ivanAltNavLinks } from '../constants'
import Layout from '../components/layout'
import HelmetWrapper from '../components/helmet-wrapper'
import AlternateNavigation from '../components/alternate-navigation'
import HeaderSans from '../components/headers/header-sans'
import SidewaysLabelSection from '../components/headers/sideways-label-section'
import SidewaysLabel from '../components/headers/sideways-label'
import ProjectListing from '../components/projects/project-listing'
import Tags from '../components/tags/tags'

const ProjectTemplate = ({ location, data }) => {
  const project = data.contentfulProject
  const relatedProjects = project.relatedProjects

  const renderNavigation = () => {
    if (typeof window !== 'undefined') {
      if (localStorage.getItem('origin') === 'olivia') {
        return <AlternateNavigation links={oliviaAltNavLinks} />
      }

      if (localStorage.getItem('origin') === 'ivan') {
        return <AlternateNavigation links={ivanAltNavLinks} />
      }
    }

    return null
  }

  return (
    <Layout location={location} navigation={renderNavigation()}>
      <HelmetWrapper pageTitle={project.title} />
      <div>
        <div className="bg-theme2-bg-1 flex flex-col min-h-1/3 justify-end p-4">
          <div className="md:max-w-5xl md:mx-auto">
            {project.titleImage &&
              <img alt="" src={project.titleImage.file.url} />
            }
          </div>
        </div>
        <div className="bg-theme1-bg-2 p-4">
          <div className="md:max-w-2xl md:mx-auto">
            <div className="flex px-11 md:px-0 my-3">
              {project.dates && <div className="font-sans text-theme1-secondary text-xs md:text-lg mr-6 tracking-wider uppercase">{project.dates}</div>}
              {project.primaryTag && <div className="font-sans text-theme1-secondary text-xs md:text-lg tracking-wider uppercase">{project.primaryTag.tagName}</div>}
            </div>
            {project.title && <h1 className="font-serif-2 my-3 px-11 md:px-0 text-4xl md:text-8xl text-theme1-primary">{project.title}</h1>}
            {project.description && <div className="font-thin font-sans my-3 px-11 md:px-0 text-2xl md:text-3xl text-theme1-primary">{project.description.description}</div>}
            <div className="px-11 md:px-0 md:flex">
              {project.roles &&
                <div className="my-3 md:basis-1/2">
                  <SidewaysLabelSection sidewaysLabel={<SidewaysLabel text="Roles" />}>
                    <div
                      className="font-light font-sans text-theme1-primary"
                      dangerouslySetInnerHTML={{
                        __html: project.roles.childMarkdownRemark.html,
                      }}
                    />
                  </SidewaysLabelSection>
                </div>
              }
              {project.output &&
                <div className="my-3 md:basis-1/2">
                  <SidewaysLabelSection sidewaysLabel={<SidewaysLabel text="Output" />}>
                    <div
                      className="output font-light font-sans text-theme1-primary"
                      dangerouslySetInnerHTML={{
                        __html: project.output.childMarkdownRemark.html,
                      }}
                    />
                  </SidewaysLabelSection>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="m-4 md:max-w-2xl md:mx-auto md:my-12">
          {project.writeupBy && project.writeupBy.name &&
            <div className="font-sans font-light text-theme1-secondary text-sm md:text-base py-4">Writeup by {shortAuthor(project.writeupBy.name)}</div>
          }
          {project.body &&
            <div
              className="project-body font-extralight font-sans text-lg text-theme1-primary md:max-w-2xl md:mx-auto"
              dangerouslySetInnerHTML={{
                __html: project.body.childMarkdownRemark.html,
              }}
            />
          }
          {project.tags &&
            <div className="my-12">
              <SidewaysLabelSection sidewaysLabel={<SidewaysLabel text="Tags" />}>
                <Tags tags={project.tags} />
              </SidewaysLabelSection>
            </div>
          }
        </div>
        {project.relatedProjects &&
          <div className="bg-theme2-bg-1 p-4 md:max-w-2xl md:mx-auto">
            <HeaderSans text="Related Projects" tag="h2" theme2={true} />
            {relatedProjects && <ProjectListing projects={relatedProjects} theme2={true} />}
          </div>
        }
      </div>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    contentfulProject(slug: {eq: $slug}) {
      id
      title
      slug
      titleImage {
        id
        file {
          url
        }
      }
      dates
      primaryTag {
        tagName
      }
      roles {
        childMarkdownRemark {
          html
        }
      }
      output {
        childMarkdownRemark {
          html
        }
      }
      writeupBy {
        name
      }
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      tags {
        tagName
      }
      relatedProjects {
        id
        title
        slug
        description {
          description
        }
        when
      }
    }
  }
`
